import React from 'react';
import Skeleton from 'react-loading-skeleton';
import makeBlockie from 'ethereum-blockies-base64';
import { toast } from 'react-toastify';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import './ProfilePage.scss';
import MyAgentsPage from './MyAgentsPage';
import StakingPage from './StakingPage';
import NotificationsPage from './NotificationsPage';
import MyNodePoolsPage from './MyNodePoolsPage';

import CopyButton from 'common/components/CopyButton';

import ProfileDelegationsPage from './profile/ProfileDelegations';
import {
  getNotificationList,
  NotificationListAPIParams
} from 'forta-app/lib/apis/notificationAPI';
import useAccountStakeQuery, {
  AccountStakeQueryData
} from 'common/hooks/useAccountStakeQuery';
import ProfileOverview from 'forta-app/components/ProfileOverview';
import Tabs, { NavTab } from 'common/components-v2/Tabs/Tabs';
import BaseButton from 'common/components-v2/Button/BaseButton';
import useENSName from 'common/hooks/useENSName';
import useWallet from 'common/hooks/useWallet';
import { shortenHash } from 'common/lib/utils';
import ProfileRewardsPage from './profile/ProfileRewards';
import { getCurrentEpoch } from 'forta-app/lib/utils';
import AccountPage from './profile/AccountPage';

interface ProfilePageProps {
  match: {
    params: {
      address: string;
    };
  };
}

export default function ProfilePage({
  match: {
    params: { address }
  }
}: ProfilePageProps): JSX.Element {
  const walletConnection = useWallet();
  const { address: walletAddress, jwt, signed, initialized } = walletConnection;
  const isOwner = walletAddress === address.toLowerCase();

  const stakeQuery = useAccountStakeQuery({
    params: { address },
    enabled: true,
    enablePolling: false
  });

  const currentEpoch = getCurrentEpoch();

  return (
    <div className="ProfilePage">
      <div className="ProfilePage__header">
        <div className="ProfilePage__profile-pic">
          <img src={makeBlockie(address)} alt={address} />
        </div>
        <div className="ProfilePage__profile-attribute-container">
          <div className="ProfilePage__profile-subscriptions">
            <DisplaySubs jwt={jwt} address={address} />
          </div>
          <div className="ProfilePage__copy-address">
            {address} <CopyButton text={address} />
          </div>
          <DisplayTags {...stakeQuery} />
        </div>
      </div>
      <Tabs className="ProfilePage__tabs">
        <NavTab label="Overview" to={`/profile/${address}`} exact />
        {isOwner && (!initialized || signed) && (
          <>
            <NavTab
              label="Detection Bots"
              to={`/profile/${address}/bots`}
              exact
            />
            <NavTab
              label="My Node Pools"
              to={`/profile/${address}/pools`}
              exact
            />
            <NavTab label="Staking" to={`/profile/${address}/staking`} exact />
            <NavTab
              label="Delegations"
              to={`/profile/${address}/delegations`}
              exact
            />
            <NavTab label="Rewards" to={`/profile/${address}/rewards`} />
            <NavTab
              label="Subscriptions"
              to={`/profile/${address}/subscriptions`}
              exact
            />
            <NavTab label="Billing" to={`/profile/${address}/account`} exact />
          </>
        )}
      </Tabs>
      <Switch>
        <Route exact path="/profile/:id">
          <ProfileOverview address={address} stakeQuery={stakeQuery} />
        </Route>
        {isOwner && signed && (
          <>
            <Route exact path="/profile/:id/bots">
              <MyAgentsPage />
            </Route>
            <Route exact path="/profile/:id/pools">
              <MyNodePoolsPage />
            </Route>
            <Route exact path="/profile/:id/staking">
              <StakingPage address={address} />
            </Route>
            <Route exact path="/profile/:id/delegations">
              <ProfileDelegationsPage
                walletConnection={walletConnection}
                stakeQuery={stakeQuery}
              />
            </Route>
            <Route exact path="/profile/:id/rewards">
              <ProfileRewardsPage
                address={address}
                currentEpochNumber={currentEpoch}
                stakeQuery={stakeQuery}
              />
            </Route>
            <Route exact path="/profile/:id/subscriptions">
              <NotificationsPage />
            </Route>
            <Route exact path="/profile/:id/account">
              <AccountPage />
            </Route>
          </>
        )}
        {initialized && <Redirect to={`/profile/${address}`} />}
      </Switch>
    </div>
  );
}

interface DisplaySubsProps {
  address: string;
  jwt: string;
}

function DisplaySubs(props: DisplaySubsProps): JSX.Element {
  const ensQuery = useENSName(props.address);

  const subsQuery = useQuery({
    queryKey: ['DisplaySubs'],
    queryFn: async () => {
      const notifications = await getNotificationList({
        jwt: props.jwt
      } as NotificationListAPIParams);
      return notifications.length;
    }
  });

  if (ensQuery.error instanceof Error) {
    toast.error(ensQuery.error.message, { hideProgressBar: true });
    return <></>;
  }

  return (
    <div className="ProfilePage__profile-subscriptions">
      <div className="ProfilePage__profile-address">
        {ensQuery.isLoading && <Skeleton width={254} />}
        {!ensQuery.isLoading && (ensQuery.name || shortenHash(props.address))}
      </div>
      <img
        className="ProfilePage__chevron"
        src="/ChevronRight.svg"
        alt="Chevron Right"
      />
      <BaseButton
        className="ProfilePage__profile-subs"
        to={`/profile/${props.address}/subscriptions`}
      >
        <div className="ProfilePage__profile-subs-LHS">Subscriptions</div>
        <div className="ProfilePage__profile-subs-RHS">
          {subsQuery.data || 0}
        </div>
      </BaseButton>
    </div>
  );
}

function DisplayTags(props: AccountStakeQueryData): JSX.Element {
  const { fetched, error, stake } = props;
  const tags: string[] = ['Forta User'];

  if (error) {
    toast.error(error.message, { hideProgressBar: true });
  }

  if (stake) {
    if (stake.totalBotsStake.gte(1)) tags.push('Bot Deployer');
    if (stake.totalDelegationsStake.gte(1)) tags.push('Delegator');
    if (stake.totalPoolsStake.gte(1)) tags.push('Pool Owner');
  }

  return (
    <div className="ProfilePage__profile-tag-container">
      {fetched ? (
        tags.map((tag) => (
          <p key={tag} className="ProfilePage__profile-tag">
            {tag}
          </p>
        ))
      ) : (
        <>
          <Skeleton width={80} height={34} />
          <Skeleton width={80} height={34} />
        </>
      )}
    </div>
  );
}
